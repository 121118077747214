<template>
  <div class="account-page">
    <header class="df fdc alc jc-sb">
      <div class="df fdc alc">
        <div class="money">{{money}}</div>
        <span>账户余额</span>
      </div>
      <div class="link-box df fdr alc jcc">
<!--        <div @click.stop="toRecharge">充值</div>-->
<!--        <div class="line"></div>-->
        <router-link to="/exchange">兑换</router-link>
      </div>
    </header>
    <!--tab-->
    <div class="tab-box df fdr alc jc-sb border-bottom-1px">
      <div v-for="(item,index) in tab_list" :key="index"
           :class="index+1===current_tab? 'tab-item df alc jcc select-sitem':'tab-item df alc jcc'"
           @click="selectTab(index)">
        {{item}}
        <div class="line" v-if="index+1===current_tab"></div>
      </div>
    </div>
    <!--列表-->
    <yd-infinitescroll v-if="list.length>0" :callback="getUserAccountList" ref="account">
      <div class="list" slot="list">
        <div class="list-item df fdr alc jc-sb" v-for="(item,index) in list" :key="index">
          <div class="left-info df fdc jc-sb">
            <div> {{item.change_type_str}}</div>
            <span class="time">{{item.create_time|formatDate}}</span>
          </div>
          <div class="increase" v-if="item.change_type==1||item.change_type==3||item.change_type==6||item.change_type==8||item.change_type==10 || item.change_type==14">+{{item.amount}}</div>
          <div class="decrease" v-if="item.change_type==2||item.change_type==7||item.change_type==4||item.change_type==9||item.change_type==11">-{{item.amount}}</div>
        </div>
      </div>
      <!-- 数据全部加载完毕显示 -->
      <span slot="doneTip">-没有更多了-</span>
    </yd-infinitescroll>
    <!-- 空视图 -->
    <div class="empty-box" v-if="list.length===0&&is_request">
      <Empty tip="暂无数据～"></Empty>
    </div>
  </div>
</template>

<script>
import {getUserAccountList} from "@/services/my";
import {baseUrl} from "@/utils/env";
import Empty from '@/components/empty/Empty'

export default {
  name: "MyAccount",
  data() {
    return {
      page: 1, // 开始查询的下标
      listRows: 10, // 每次查询的数量
      money: 0.00,
      list: [],
      current_tab: 1,//1全部，2收入，3支出
      tab_list: ['全部', '收入', '支出'],
      is_request: 0,
    }
  },
  components: {
    Empty,
  },
  methods: {
    //tab切换
    selectTab(index) {
      this.current_tab = index + 1;
      this.list = [];
      this.page = 1;
      this.is_request = 0;
      this.getUserAccountList()
    },
    async getUserAccountList() {
      try {
        let params = {
          page: this.page,
          listRows: this.listRows,
          type: this.current_tab, //1全部，2收入，3支出
        }
        const res = await getUserAccountList(params);
        this.is_request = 1;
        if (res.code === 0) {
          const data = res.data;
          this.money = data.money
          this.list = [...this.list, ...data.list];
          this.total = data.total;
          if (this.list.length === data.total) {
            /* 所有数据加载完毕 */
            this.page++;
            this.$refs.account.$emit('ydui.infinitescroll.loadedDone');
            return;
          }
          /* 单次请求数据完毕 */
          this.page++;
          this.$refs.account.$emit('ydui.infinitescroll.finishLoad');
        }
      } catch (e) {

      }
    },
    toRecharge() {
      window.location.href = `${baseUrl}/#/recharge`
    },
  },
  mounted() {
    this.getUserAccountList()
  }
}
</script>

<style scoped lang="less">
@import "../../../common/less/variable.less";
@import "../../../common/less/mixin.less";


.account-page {
  header {
    .sc(0.3rem, @color-white);
    .wh(100%, 3.88rem);
    .bis("../../../assets/img_zhanghu_bg");
    padding-top: 0.8rem;

    .money {
      .sc(0.64rem, @color-white);
      line-height: 0.9rem;
    }

    .link-box {
      .wh(100%, 1rem);
      .sc(0.32rem, @color-white);
      padding: 0 1.54rem;

      .line {
        .wh(0.02rem, 0.6rem);
        background: linear-gradient(180deg, rgba(255, 255, 255, 0.7) 0%, rgba(255, 255, 255, 1) 50%, rgba(255, 255, 255, 0.7) 100%);
        opacity: 0.5;
      }
    }
  }

  /*tab*/

  .tab-box {
    .sc(0.32rem, @color-light-grey);
    .wh(100%, 1.04rem);
    background: @color-white;
    padding: 0 0.62rem;

    .tab-item {
      .wh(1.26rem, 100%);
      border-bottom: 0.04rem solid transparent;
    }

    .select-sitem {
      .sc(0.32rem, var(--main-color));
      border-bottom: 0.04rem solid var(--main-color);

    }
  }

  /*列表*/

  .list {
    padding: 0 0.24rem;
    background: @color-white;

    .list-item {
      .wh(100%, 1.4rem);
      padding: 0.24rem 0;
      border-bottom: 1px solid #F0F0F0;
      .sc(0.32rem, @color-dark-grey);

      .left-info {
        height: 100%;

        div {
          line-height: 0.44rem;
        }
      }

      .time {
        .sc(0.24rem, @color-light-grey);
      }

      /*增加*/

      .increase {
        .sc(0.36rem, @color-add-red);
      }

      /* 减少*/

      .decrease {
        .sc(0.36rem, var(--main-color));

      }
    }
  }
  /* 列表为空*/

  .empty-box {
    height: ~"calc(100vh - 4.92rem)";
    .empty{
      margin-top: 2rem;
    }
  }

}

</style>
